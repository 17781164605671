import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import useUserHook from "../../hooks/UserHook";
import OrderList from "../Orders/OrderList";

function Content() {
    const [tabName, setTabName] = useState<string>("in-progress");

    const { user } = useUserHook();

    function handleChange(event: React.SyntheticEvent, newValue: string) {
        setTabName(newValue);
    }

    return (
        <div className="p-4">
            <div className="flex gap-4 items-center">
                <h2 className="text-4xl font-medium text-gray-900">Orders</h2>
                <div className="flex w-full items-end justify-end pr-3 ">
                    {/* <DatePicker /> */}
                </div>
            </div>
            <Tabs
                className="mb-4"
                value={tabName}
                onChange={handleChange}
                aria-label="basic tabs example"
            >
                <Tab label="In-Progress" value="in-progress" />

                {user.superAdmin === 1 && (
                    <Tab label="Fulfilled" value="fulfilled" />
                )}
            </Tabs>

            {tabName === "in-progress" && <OrderList filled={false} />}

            {tabName === "fulfilled" && <OrderList filled={true} />}
        </div>
    );
}

export default Content;
