export const SIGNATURE = "NFVCr9h0sgenwOVQhXCp0s7pl16FI8dH";

export const APIURL =
    process.env.NODE_ENV === "production"
        ? "https://api.om.balingwiredirect.com"
        : "http://api.om.bwd.test";

export const SHOP_URL = process.env.NODE_ENV === "production"
    ? "https://shop.balingwiredirect.com/v3/api"
    : "http://shop.bwd.test/v3/api";

export const VERSION = "0.0.5";
