import { Button, Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState, useContext } from 'react'
import Header from '../Layouts/Header'
import Sidebar from '../Layouts/Sidebar'
import userHook from '../../hooks/UserHook'
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom'
import { Context } from '../../contexts/Store'
import AddIcon from '@mui/icons-material/Add';


function Users() {

    const [rows, setRows] = useState<any>([]);
    const { getUserList } = userHook();
    const [loading, setLoading] = useState<boolean>(false);
    const { state, setState } = useContext(Context);

    useEffect(() => {

        setState({ ...state, loading: true });
        setLoading(true);
        getUserList().then((res: any) => {
            setState({ ...state, loading: false });
            setLoading(false);
            setRows(res)
        }).catch(() => {
            setState({ ...state, loading: false });
        });
    }, []);

    return (
        <div className="flex">
            <Sidebar />
            <div className="content w-full bg-gray-100 h-full min-h-screen">
                <Header />
                <div className="p-4">
                    <div className="flex items-center mb-4 justify-between">
                        <h2>Users</h2>
                        <Button
                            variant="contained"
                            to="/user/create"
                            component={Link}
                            startIcon={<AddIcon />}
                        >
                            Add New User
                        </Button>
                    </div>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Name</TableCell>
                                    <TableCell align="left">Email</TableCell>
                                    <TableCell align="left">Super Admin</TableCell>
                                    <TableCell align="left">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row: any) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                        <TableCell component="th" scope="row">{row.name}</TableCell>
                                        <TableCell align="left">{row.email}</TableCell>
                                        <TableCell align="left">
                                            {(row.superAdmin ? <Chip size="small" label="Yes" color="success" /> : '')}
                                        </TableCell>
                                        <TableCell>
                                            <Button to={`/user/edit/${row.id}`} component={Link} variant="text">
                                                <EditIcon />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
    )
}

export default Users;


