import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import LogInPage from "./components/Layouts/LogInPage";
import UserEdit from "./components/Users/UserEdit";
import Users from "./components/Users/Users";
import AuthProvider from "./contexts/AuthContext";

function App() {
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <AuthProvider>
                        <Home />
                    </AuthProvider>
                }
            />
            <Route
                path="/users"
                element={
                    <AuthProvider>
                        <Users />
                    </AuthProvider>
                }
            />
            <Route
                path="/user/create"
                element={
                    <AuthProvider>
                        <UserEdit />
                    </AuthProvider>
                }
            />
            <Route
                path="/user/edit/:id"
                element={
                    <AuthProvider>
                        <UserEdit />
                    </AuthProvider>
                }
            />
            <Route path="/login" element={<LogInPage />} />
        </Routes>
    );
}

export default App;
