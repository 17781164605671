import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import * as React from "react";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackBar({
    open,
    handleClose,
    status,
    message
}: {
    open: boolean;
    handleClose: () => void;
    status: "success" | "error" | "warning" | "info";
    message: string
}) {
    return (
        <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity={status}
                    sx={{ width: "100%" }}
                >
                   {message}
                </Alert>
            </Snackbar>
        </Stack>
    );
}
