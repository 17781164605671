import { createContext, useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import useAxiosHook from "../hooks/AxiosHook";
import { Context } from "./Store";

export const AuthContext: any = createContext('auth');

const AuthProvider = ({ children }: any) => {

    const [userClient] = useState(localStorage.getItem('bwd-vendor-client'));

    const [user, setUser] = useState({});

    const { setState, state } = useContext(Context);

    const { service } = useAxiosHook();

    useEffect(() => {
        service.get('/user').then((res: any) => {
            setUser(res.data);
            setState({ ...state, user: res.data });
        })
    }, []);

    return userClient ? (
        <AuthContext.Provider value={{ user }}>
            {children}
        </AuthContext.Provider>
    ) : <Navigate to="/login" />
}

export default AuthProvider;