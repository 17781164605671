import LoopIcon from "@mui/icons-material/Loop";
import SendIcon from "@mui/icons-material/Send";
import { Dialog, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import axios from "axios";
import { forwardRef, ReactElement, Ref, useEffect, useState } from "react";
import { SHOP_URL, SIGNATURE } from "../Helper";
import useUserHook from "../hooks/UserHook";
import { AlertType } from "../types";
import SnackBar from "./SnackBar";

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>;
    },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface OrderInfo {
    orderId: number | null;
    vendorId: number | null;
}

type ConfirmationModalProps = {
    open: boolean;
    handleClose: () => void;
    orderInfo: OrderInfo;
    onFetchOrders: () => void;
    onProcessReady: () => void;
};

export default function ConfirmationModal({
    open,
    handleClose,
    orderInfo,
    onFetchOrders,
    onProcessReady,
}: ConfirmationModalProps) {
    const { user } = useUserHook();

    const [showUploader, setShowUploader] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [alertInfo, setAlertInfo] = useState<AlertType>({
        open: false,
        status: "success",
        message: "",
    });

    /** Handling the send message functionality by calling the server API */
    function processSendMessage() {
        const formData = new FormData();
        formData.append("orderId", String(orderInfo.orderId));
        formData.append("vendorId", String(orderInfo.vendorId));
        formData.append("customMessage", message);
        formData.append("loginUserId", String(user?.id));
        setIsLoading(true);
        axios
            .post(SHOP_URL + "/om-shipping-doc-send", formData, {
                headers: {
                    Accept: "application/json",
                    signature: SIGNATURE || "",
                },
            })
            .then(({ data, status }) => {
                if (status === 200) {
                    setAlertInfo({
                        open: true,
                        status: "success",
                        message: data.message,
                    });
                    onProcessReady();
                }
            })
            .catch((err) => {
                setAlertInfo({
                    open: true,
                    status: "error",
                    message:
                        err.response?.data?.message ||
                        "Something went wrong, try again",
                });
            })
            .finally(() => {
                setIsLoading(false);
                handleClose();
            });
    }

    useEffect(() => {
        setMessage(
            `Hello Team, here is the document attached to process the order ${orderInfo.vendorId ||
            "(Please write your vendor order number here)"
            }`
        );
    }, [orderInfo]);

    return (
        <>
            <SnackBar
                open={alertInfo.open}
                status={alertInfo.status}
                handleClose={() =>
                    setAlertInfo((prev) => ({ ...prev, open: false }))
                }
                message={alertInfo.message}
            />

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Send Docs"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Do you want to send the docs, Shipping Label and BOL, to
                        the shipping team?
                    </DialogContentText>

                    {showUploader && (
                        <TextField
                            sx={{ mt: 2 }}
                            fullWidth
                            id="customize-message"
                            value={message}
                            label="Customize your message here"
                            onChange={(e) => setMessage(e.target.value)}
                            variant="outlined"
                            multiline
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <div className="p-4">
                        {showUploader && (
                            <Button
                                variant="contained"
                                onClick={processSendMessage}
                                endIcon={
                                    isLoading ? (
                                        <LoopIcon className="animate-spin" />
                                    ) : (
                                        <SendIcon />
                                    )
                                }
                            >
                                Send
                            </Button>
                        )}

                        {!showUploader && (
                            <Button onClick={() => setShowUploader(true)}>
                                Yes
                            </Button>
                        )}

                        <Button sx={{ ml: 2 }} onClick={() => onProcessReady()}>
                            No, Update the Status Only
                        </Button>

                        <Button sx={{ ml: 2 }} onClick={handleClose}>
                            Cancel
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
}