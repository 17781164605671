import NotificationsIcon from "@mui/icons-material/Notifications";
import LightModeIcon from "@mui/icons-material/LightMode";
import DropdownMenu from "./DropdownMenu";
import { Box, LinearProgress, Alert } from '@mui/material';
import { useContext, useEffect, useState } from "react";
import { Context } from '../../contexts/Store';
import useUserHook from "../../hooks/UserHook";
import WarehousesMenu from "./WarehousesMenu";
function Header() {

    const { state, setState } = useContext(Context);
    const { getWarehouses } = useUserHook();
    const { user } = useUserHook();

    useEffect(() => {
        if (user && user.id && state.warehouses.length < 1) {
            getWarehouses().then(res => {
                setState({ ...state, warehouses: res });
            });
        }
    }, [user.id]);

    return (
        <>
            <div className="flex shadow-sm items-center bg-white py-3">
                <span className=" flex text-gray-700  ml-2 justify-center items-center h-fit">
                    <h1 className=" pl-1 text-lg">Dashboard</h1>
                </span>
                <div className=" ml-0  pr-3 flex w-full justify-end space-x-4 items-center">
                    {/* <LightModeIcon />
          <NotificationsIcon /> */}
                    <div className=" flex px-1  items-center">
                        <WarehousesMenu warehouses={state.warehouses} />
                    </div>
                    <div className=" flex px-1  items-center">
                        <DropdownMenu />
                    </div>
                </div>
            </div>
            {state.loading && (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            )}

            {state.successMessage.length > 0 && (
                <Alert severity="success" color="success">
                    {state.successMessage}
                </Alert>
            )}
        </>
    );
}

export default Header;
