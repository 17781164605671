import Content from "./Layouts/Content";
import Header from "./Layouts/Header";
import Sidebar from "./Layouts/Sidebar";

function Home() {
    return (
        <div className="flex">
            <Sidebar />
            <div className="content w-full bg-gray-100 min-h-screen">
                <Header />
                <Content />
            </div>
        </div>
    );
}

export default Home;
