import NewspaperIcon from "@mui/icons-material/Newspaper";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { Chip } from "@mui/material";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha, styled } from "@mui/material/styles";
import React from "react";

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 170,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));

type AddNoteProps = {
    openModal: (isOpen?: boolean) => void;
    onOpenSavedNotesModal: (isSaved: boolean) => void;
    notesCount: number;
};

function AddNote({
    openModal,
    onOpenSavedNotesModal,
    notesCount,
}: any) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState<boolean>(false);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    const openSavedNotes = () => {
        onOpenSavedNotesModal(true);
        setOpen(false);
        setAnchorEl(null);
    };

    return (
        <div>
            <Chip
                label={notesCount + (notesCount > 1 ? " Notes" : " Note")}
                onClick={handleClick}
            />
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => openModal(true)} disableRipple>
                    <NoteAddIcon />
                    Add note
                </MenuItem>
                {notesCount > 0 && (
                    <MenuItem onClick={openSavedNotes} disableRipple>
                        <NewspaperIcon />
                        View Notes
                    </MenuItem>
                )}
            </StyledMenu>
        </div>
    );
}

export default AddNote;
