import axios, { AxiosResponse } from "axios";
import { SHOP_URL, SIGNATURE } from "../Helper";
import useAxiosHook from "./AxiosHook";

function useOrderHook() {
    const { service } = useAxiosHook();

    const statuses: any = {
        1: "In Progress",
        2: "Shipping Today",
        3: "Shipped",
        4: "Delivered",
    };

    function getInProgressOrders(filled: boolean, page: number) {
        return service
            .get("/order?filled=" + filled + "&page=" + page)
            .then((res: any) => {
                return res.data;
            });
    }

    async function updateOrderTrackingStatus(orderId: number) {
        return axios
            .post(
                SHOP_URL + "/om-order-tracking-status",
                {
                    orderId: orderId,
                },
                {
                    headers: {
                        Accept: "application/json",
                        signature: SIGNATURE || "",
                    },
                }
            )
            .catch((err) => {
                console.log(err);
            });
    }

    async function updateOrderShippingTime(orderId: number, time: string) {
        return service
            .put("/order/update/data", {
                adminOrderId: orderId,
                estimatedShippingTime: time,
            })
            .then((res: AxiosResponse) => {
                if (res.data.status === "success") {
                    if (time === "Ready") {
                        updateOrderTrackingStatus(orderId);
                    }
                }
            });
    }

    async function updateVendorOrderNumber(
        orderId: number,
        vendorOrderNumber: string
    ) {
        return service
            .put("/order/update/data", {
                adminOrderId: orderId,
                vendorOrderNumber,
            })
            .then((res: AxiosResponse) => {
                if (res.data.status === "success") {
                    // alert(res.data.message);
                }
            });
    }

    function orderStatus(statusId: number) {
        return statuses[statusId];
    }

    function createNote(orderId: number, text: string) {
        return service
            .post("/order/note/create", {
                orderId,
                text,
            })
            .then((res: any) => res.data)
            .catch((e: any) => e);
    }

    function orderNotes(orderId: number) {
        return service
            .get("/order/notes/" + orderId)
            .then((res: any) => res.data)
            .catch((e: any) => e);
    }

    return {
        getInProgressOrders,
        updateOrderShippingTime,
        orderStatus,
        updateVendorOrderNumber,
        createNote,
        orderNotes,
    };
}

export default useOrderHook;
