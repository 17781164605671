
import React, { useEffect } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import useOrderHook from '../../hooks/OrderHook';
import { Alert, AlertTitle } from '@mui/material';
import useUserHook from '../../hooks/UserHook';
import moment from 'moment';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '4px',
    boxShadow: 24,
    p: 2,
    maxHeight: '80%',
    overflow: 'auto'
};

function SaveNotes({ openSaveModal, closeSaveModal, orderId }: any) {
    const [open, setOpen] = React.useState(false);
    const [saveNote, setSaveNote] = React.useState<any>(null);
    const [note, setNote] = React.useState<any>(null);
    const [id, setId] = React.useState<any>(null);
    const [notes, setNotes] = React.useState<any[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const { orderNotes } = useOrderHook();
    const { user } = useUserHook();

    useEffect(() => {

        if (!orderId || !openSaveModal) {
            return;
        }

        setLoading(true);
        orderNotes(orderId).then((res: any) => {
            setNotes(res);
        }).finally(() => setLoading(false));

    }, [orderId, openSaveModal]);

    return (
        <div>
            <Modal
                open={openSaveModal}
                onClose={closeSaveModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Notes
                    </Typography>
                    <div id="modal-modal-description" className="mt-2">
                        {!loading && notes.length < 1 && (
                            <Alert severity="warning">
                                There is no notes found for this order
                            </Alert>
                        )}
                        {!loading && notes.length > 0 && notes.map((note: any, key: number) => (
                            <div className={((notes.length - 1) !== key ? "mb-2" : '')} key={'note-' + note.id}>
                                <Alert icon={false} severity={note.userId === user.id ? 'info' : "warning"}>
                                    <AlertTitle>{note.user.name}</AlertTitle>
                                    <div className="break-words" dangerouslySetInnerHTML={{ __html: note.note }}>
                                    </div>
                                </Alert>
                                <div className="flex justify-end text-sm text-gray-600 mt-2">
                                    {moment(note.createdAt).format('MMM D, h:mm A')}
                                </div>
                            </div>
                        ))}
                    </div>
                </Box>
            </Modal>
        </div>
    );
}



export default SaveNotes

