import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Input, TextField } from "@mui/material";
import axios from "axios";
import useOrderHook from "../../hooks/OrderHook";


const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 3,
};

function NoteModal({ isOpen, onClose, orderId, onCreated }: any) {
    const [note, setNote] = React.useState<any>('');
    const [id, setId] = React.useState<any>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const { createNote } = useOrderHook();

    const changeSaveText = (e: any) => {
        setNote(e.currentTarget.value);
    };

    const clickSaveHandle = (e: any) => {
        setLoading(true);
        createNote(orderId, note).then((res: any) => {
            if (res && res.status === 'success') {
                alert(res.message);
                setNote("");
                onClose(true);
                onCreated(true);
            }
        }).finally(() => setLoading(false));
    };
    return (
        <div>
            <Modal
                open={isOpen}
                onClose={() => onClose(true)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="flex justify-between">
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Note
                        </Typography>
                    </div>
                    <TextField
                        value={note}
                        onChange={changeSaveText}
                        multiline
                        rows={10}
                        placeholder="Write Note..."
                        id="modal-modal-description"
                        sx={{
                            mt: 2,
                            width: "100%",
                            align: "center",
                        }}
                    ></TextField>
                    <div className="mt-6 flex justify-end">
                        <Button
                            onClick={clickSaveHandle}
                            variant="contained"
                            size="large"
                        >
                            {loading ? 'Saving...' : 'Save Note'}
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default NoteModal;
