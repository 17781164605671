import { createContext, useState } from "react";

export const Context = createContext();

const Store = ({ children }) => {
    const [state, setState] = useState({
        user: null,
        loading: false,
        successMessage: true,
        warehouses: [],
        reLoginRequired: false
    });

    return (
        <Context.Provider value={{ state, setState }}>
            {children}
        </Context.Provider>
    )
}

export default Store;