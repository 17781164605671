import React, { useContext } from "react";

import { Link } from "react-router-dom";
import { Drawer, IconButton, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import BwdLogo from '../Images/bwd-logo.png';
import StorageIcon from '@mui/icons-material/Storage';
import GroupIcon from '@mui/icons-material/Group';
import { Context } from "../../contexts/Store";
import { AuthContext } from "../../contexts/AuthContext";

function Sidebar() {
    const [open, setOpen] = React.useState<boolean>(true);
    const { user } = useContext(AuthContext);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const menu: any = document.querySelector('.sideDrawer');

    const drawerOpen = (e: any) => {
        setOpen(true);
        menu.classList.add('opened');
    }

    const drawerClose = (e: any) => {
        setOpen(false);
        menu.classList.remove('opened');
    }

    return (
        <>
            <Drawer onMouseEnter={drawerOpen} onMouseLeave={drawerClose} className="sideDrawer" variant="permanent" open={open}>
                <List>
                    <div className="shadow-sm logo-wrapper flex items-center">
                        <img className={(open ? 'logo-full' : 'logo-icon')} src={BwdLogo} />
                    </div>
                    <ListItem disablePadding sx={{ display: 'block' }} to="/" component={Link}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <StorageIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Orders'} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                    {user && (user.role === 'SuperAdmin') && (
                        <ListItem disablePadding sx={{ display: 'block' }} to="/users" component={Link}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <GroupIcon />
                                </ListItemIcon>
                                <ListItemText primary={'Users'} sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>
                    )}
                </List>
            </Drawer>
            {/*
        <div className="">
          {/* <div className="logo pt-3 ">
              <img src="https://nocookie.balingwiredirect.com/img/bwd-logo.jpg" />
            </div> 
            </div >
          */}
        </>
    );
}

export default Sidebar;
