import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';
import Menu, { MenuProps } from '@mui/material/Menu';
import { alpha, styled } from '@mui/material';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import { Context } from '../../contexts/Store';
import useUserHook from '../../hooks/UserHook';

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }: any) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 170,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

function WarehousesMenu({ warehouses }: any) {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { user } = useUserHook();
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function changeWarehouse(warehouseId: number | null) {

        if (!warehouseId) {
            localStorage.removeItem('bwd-vendor-warehouse');
        } else {
            localStorage.setItem('bwd-vendor-warehouse', warehouseId.toString());
        }

        setAnchorEl(null);
        window.location.reload();
    }

    return (
        <div>
            {warehouses && typeof warehouses !== 'undefined' && warehouses.length > 0 && (
                <Button
                    id="demo-customized-button"
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="outlined"
                    disableElevation
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                    startIcon={<WarehouseIcon />}
                    color="secondary"
                >
                    {warehouses.find((warehouse: any) => warehouse.id.toString() === localStorage.getItem('bwd-vendor-warehouse')?.toString())?.name || 'All Warehouses'}
                </Button>
            )}
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {(user.superAdmin === 1 || (warehouses && warehouses.length > 0)) && (
                    <MenuItem onClick={() => changeWarehouse(null)}>
                        All Warehouses
                    </MenuItem>
                )}

                {warehouses.map((warehouse: any) => (
                    <MenuItem key={"warehouse-dropdown-" + warehouse.id} onClick={() => changeWarehouse(warehouse.id)}>
                        {warehouse.name}
                    </MenuItem>
                ))}
            </StyledMenu>
        </div>
    )
}

export default WarehousesMenu
