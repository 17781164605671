import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Context } from "../../contexts/Store";
import useUserHook from "../../hooks/UserHook";
import Header from "../Layouts/Header";
import Sidebar from "../Layouts/Sidebar";

interface WarehouseInterface {
    id?: number;
    warehouseId: number;
    name?: string;
    role: number;
}

function UserEdit() {
    const { id } = useParams();
    const [user, setUser] = useState<any>({});
    const [userWarehouses, setUserWarehouses] = useState<WarehouseInterface[]>(
        []
    );
    const [warehouses, setWarehouses] = useState<WarehouseInterface[]>([]);
    const {
        getWarehouses,
        getUserForEditing,
        getRoles,
        updateUser,
        user: loggedInUser,
        createUser,
    } = useUserHook();
    const [loading, setLoading] = useState<boolean>(false);
    const { setState, state } = useContext(Context);
    const navigate = useNavigate();

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        if (state.loading) {
            return;
        }

        if (id) {
            setState({ ...state, loading: true });
            updateUser({ ...user, warehouses: userWarehouses })
                .then((res: any) => {
                    setState({ ...state, loading: false });

                    if (res && res.status === "success") {
                        setState({ ...state, successMessage: res.message });
                        setTimeout(
                            () => setState({ ...state, successMessage: "" }),
                            5000
                        );
                    }
                })
                .catch(() => setState({ ...state, loading: false }));
            return;
        }

        if (!user.superAdmin && userWarehouses.length < 1) {
            alert("Please choose at least one warehouse and the role");
            return;
        }

        if (userWarehouses.find((warehouse) => !warehouse.role)) {
            alert("Please choose a role for every selected warehouses");
            return;
        }

        if (!user.password) {
            alert("Password field is required");
            return;
        }

        if (user.password.length < 8) {
            alert("Password should have least 8 character");
            return;
        }
        setState({ ...state, loading: true });
        createUser({ ...user, warehouses: userWarehouses })
            .then((res: any) => {
                setState({ ...state, loading: false });

                if (res) {
                    setState({ ...state, successMessage: res.message });
                    navigate("/user/edit/" + res.userId);
                }
            })
            .catch(() => setState({ ...state, loading: false }));
    }

    function warehouseExist(warehouseId: number) {
        return (
            userWarehouses.filter(
                (warehouse: any) =>
                    warehouse.warehouseId.toString() === warehouseId.toString()
            ).length > 0
        );
    }

    function modifyWarehouseArray(e: any) {
        const checked = e.target.checked;
        const warehouseId = +e.target.value;

        if (checked) {
            setUserWarehouses([...userWarehouses, { warehouseId, role: 0 }]);
        } else {
            // Remove
            const removedWarehousesArray: WarehouseInterface[] =
                userWarehouses.filter(
                    (warehouse: any) =>
                        warehouse.warehouseId.toString() !==
                        warehouseId.toString()
                );
            console.log(removedWarehousesArray);
            setUserWarehouses(removedWarehousesArray);
        }
    }

    function addRoleToWarehouse(roleId: number, warehouseId: number) {
        setUserWarehouses(
            userWarehouses.map((warehouse: WarehouseInterface) => {
                if (
                    warehouse.warehouseId.toString() === warehouseId.toString()
                ) {
                    warehouse.role = roleId;
                }
                return warehouse;
            })
        );
    }

    function userRole(warehouseId: number) {
        return userWarehouses.find(
            (warehouse: WarehouseInterface) =>
                warehouse.warehouseId.toString() === warehouseId.toString()
        )?.role;
    }

    useEffect(() => {
        if (id) {
            getUserForEditing(+id)
                .then((u) => {
                    setUser(u);
                    setUserWarehouses(u.warehouses);
                })
                .then(() =>
                    setTimeout(
                        () => setState({ ...state, successMessage: "" }),
                        3000
                    )
                );
        }
    }, [id]);

    useEffect(() => {
        getWarehouses().then((warehouses: WarehouseInterface[]) =>
            setWarehouses(warehouses)
        );

        if (id) {
            setLoading(true);
            getUserForEditing(+id)
                .then((u) => {
                    setUser(u);
                    setUserWarehouses(u.warehouses);
                })
                .then(() => setLoading(false));
        }
    }, []);

    return (
        <div className="flex">
            <Sidebar />
            <div className="content w-full bg-gray-100 min-h-screen">
                <Header />
                {loading && (
                    <Box
                        className="min-h-screen flex items-center justify-center"
                        sx={{ display: "flex", justifyContent: "center" }}
                    >
                        <CircularProgress />
                    </Box>
                )}

                {!loading && (
                    <div className="p-4">
                        <h2 className="mb-2">
                            {id ? "Edit:" : "Add New User"} {user.name}
                        </h2>
                        <Box
                            className=""
                            onSubmit={handleSubmit}
                            component="form"
                        >
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="Name"
                                label="Name"
                                id="Name"
                                autoComplete="Name"
                                value={user.name || ""}
                                onChange={(e: any) =>
                                    setUser({ ...user, name: e.target.value })
                                }
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                // error={emailError}
                                // helperText={emailHelperText}
                                autoFocus
                                type="email"
                                value={user.email || ""}
                                onChange={(e: any) =>
                                    setUser({ ...user, email: e.target.value })
                                }
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                helperText="Min 8 character"
                                onChange={(e: any) =>
                                    setUser({
                                        ...user,
                                        password: e.target.value,
                                    })
                                }
                            />

                            {loggedInUser.id !== user.id &&
                                loggedInUser.superAdmin === 1 && (
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={
                                                    user.superAdmin || false
                                                }
                                                onChange={(e: any) =>
                                                    setUser({
                                                        ...user,
                                                        superAdmin:
                                                            e.target.checked,
                                                    })
                                                }
                                            />
                                        }
                                        label="Super Admin"
                                    />
                                )}

                            {loggedInUser.id !== user.id &&
                                !user.superAdmin && (
                                    <div className="mt-4">
                                        <h2 className="mb-2">Warehouses</h2>
                                        {warehouses.map((warehouse: any) => (
                                            <div
                                                key={
                                                    "warehouse-" + warehouse.id
                                                }
                                            >
                                                <div className="flex items-center">
                                                    <FormControlLabel
                                                        label={warehouse.name}
                                                        control={
                                                            <Checkbox
                                                                checked={
                                                                    warehouseExist(
                                                                        +warehouse.id
                                                                    )
                                                                        ? true
                                                                        : false
                                                                }
                                                                onChange={
                                                                    modifyWarehouseArray
                                                                }
                                                                value={
                                                                    +warehouse.id
                                                                }
                                                            />
                                                        }
                                                        sx={{ width: "150px" }}
                                                    />
                                                    {warehouseExist(
                                                        +warehouse.id
                                                    ) && (
                                                        <Select
                                                            required={true}
                                                            id={
                                                                "warehouse-role-" +
                                                                warehouse.id
                                                            }
                                                            label="Role"
                                                            value={
                                                                userRole(
                                                                    +warehouse.id
                                                                ) || "something"
                                                            }
                                                            sx={{
                                                                height: "30px",
                                                            }}
                                                            onChange={(
                                                                e: any
                                                            ) =>
                                                                addRoleToWarehouse(
                                                                    e.target
                                                                        .value,
                                                                    +warehouse.id
                                                                )
                                                            }
                                                        >
                                                            <MenuItem
                                                                value={
                                                                    "something"
                                                                }
                                                            >
                                                                Choose Role
                                                            </MenuItem>
                                                            {getRoles()?.map(
                                                                (role: any) => (
                                                                    <MenuItem
                                                                        key={
                                                                            "role+" +
                                                                            role.id
                                                                        }
                                                                        value={
                                                                            role.id
                                                                        }
                                                                    >
                                                                        {
                                                                            role.name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}

                            <div className="mt-4">
                                <Button type="submit" variant="contained">
                                    Save
                                </Button>
                            </div>
                        </Box>
                    </div>
                )}
            </div>
        </div>
    );
}

export default UserEdit;
