import React, { useContext, useState } from 'react'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { Alert, CircularProgress } from '@mui/material';
import useAxiosHook from '../../hooks/AxiosHook';
import { Context } from '../../contexts/Store';

const theme = createTheme();

function LogInPage() {
    const [passwordError, setPasswordError] = useState<boolean>(false);
    const [passwordHelperText, setPasswordHelperText] = useState<string | null>(null);
    let navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const { service } = useAxiosHook();
    const { state, setState } = useContext(Context);
    const appVersion: string | undefined = process.env.REACT_APP_VERSION || '0.0.3';

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let password = event.currentTarget.password.value;

        if (password.length < 8) {
            setPasswordError(true);
            setPasswordHelperText('Password must contain atleast 8 character');
            return false;
        }

        setLoading(true);
        service.post('/user/auth', {
            email: event.currentTarget.email.value,
            password: event.currentTarget.password.value
        }).then(res => {
            if (res.data && res.data.status === 'success') {

                setState({ ...state, reLoginRequired: false });

                localStorage.setItem('bwd-vendor-client', res.data.accessToken);

                if (res.data.warehouseId) {
                    // localStorage.setItem('bwd-vendor-warehouse', res.data.warehouseId);
                }

                setTimeout(() => {
                    navigate('/');
                }, 500);
            }
        }).finally(() => setLoading(false));

    };

    return (

        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >

                    <div className="pr-6 mb-10    ">
                        <img alt="Baling Wire Direct" src="https://nocookie.balingwiredirect.com/img/bwd-logo.jpg" />
                    </div>

                    <Typography component="h1" variant="h5">
                        Sign In
                    </Typography>

                    {state.reLoginRequired && (
                        <Alert sx={{ width: '100%', marginTop: '16px' }} severity="info">
                            Please Sign In Again
                        </Alert>
                    )}

                    <Box onSubmit={handleSubmit} component="form" sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            // error={emailError}
                            // helperText={emailHelperText}
                            autoFocus
                            type="email"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            error={passwordError}
                            helperText={passwordHelperText}
                            onChange={(e: any) => {
                                if (e.target.value.length >= 8 && passwordError) {
                                    setPasswordError(false);
                                    setPasswordHelperText('');
                                }
                            }}
                        />
                        {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                            className="mb-3"
                        /> */}
                        <div className="mt-4">
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                size="large"
                                color="primary"
                                disableElevation
                                disabled={loading}
                                sx={{ height: '55px' }}
                            >
                                {loading ? <CircularProgress color="inherit" /> : 'Sign In'}
                            </Button>
                        </div>

                        <div className="text-center text-gray-500 mt-8">
                            {appVersion}
                        </div>
                        {/* <Grid container className="mt-3">
                            <Grid item xs>
                                <Link href="#" variant="body2" underline="none">
                                    Forgot password?
                                </Link>
                            </Grid>
                        </Grid> */}
                    </Box>

                </Box>
            </Container>
        </ThemeProvider >
    )
}


export default LogInPage