import axios from 'axios';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../contexts/Store';
import { APIURL } from '../Helper';

function useAxiosHook() {

    const { state, setState } = useContext(Context);

    const service = axios.create({
        baseURL: APIURL
    });

    const navigate = useNavigate();

    const successResponse = (response) => { return response; }

    const errorResponse = (e) => {

        if (!e.response) {
            return {};
        }

        if (e.response.status && e.response.status === 403) {
            localStorage.removeItem('bwd-vendor-warehouse');
            localStorage.removeItem('bwd-vendor-client');
            setState({ ...state, reLoginRequired: true });
            setTimeout(() => {
                navigate('/login');
                return;
            }, 500);
            return e.response;
        }

        if (e.response.status && e.response.status === 401) {
            localStorage.removeItem('bwd-vendor-warehouse');
            localStorage.removeItem('bwd-vendor-client');
            setState({ ...state, reLoginRequired: true });
            setTimeout(() => {
                navigate('/login');
                return;
            }, 500);
            return e.response;
        }

        if (e.response.data && e.response.data.message) {
            alert(e.response.data.message);
        }

        return e.response;
    }

    service.interceptors.response.use(successResponse, errorResponse);
    service.interceptors.request.use((config) => {
        config.headers.Authorization = "Bearer " + localStorage.getItem('bwd-vendor-client');
        config.headers.signature = 'bwdShopV4.Y2wEddduTtey4IrC';
        if (localStorage.getItem('bwd-vendor-warehouse')) {
            config.params = {
                warehouseId: localStorage.getItem('bwd-vendor-warehouse')
            };
        }
        return config;
    });

    return {
        service
    };
}

export default useAxiosHook
